import React from 'react';
import Drawer from "./components/Drawer";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2D7DD2",
      // main: "#84DD63",
      // main: "#CBFF4D",
      // main: "#97CC04",
      // main: "#16C172",
    },
    secondary: {
      main: "#84DD63",
      // main: "#CBFF4D",
      // main: "#97CC04",
      // main: "#2D7DD2",
      // main: "#16C172",
    },
  },
});

function App() {
  return (
    <div className="App" >
      <ThemeProvider theme={theme}>
        <Drawer />
      </ThemeProvider>
    </div>
  );
}

export default App;
