import React, {useRef, useEffect, useState} from 'react';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
      margin: theme.spacing(1)
  },
  spellName: {
      fontStyle: "italic",
      color: theme.palette.primary.main,
      fontWeight: 700
  },
  spellEffectText: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      fontSize: "1.1rem"
  }
}));

export const MagicSpells = (props) => {
    const classes = useStyles();
    const [firstWandMotion, setFirstWandMotion] = useState('');
    const [secondWandMotion, setSecondWandMotion] = useState('');
    const [firstPhrase, setFirstPhrase] = useState('');
    const [secondPhrase, setSecondPhrase] = useState('');
    const [action, setAction] = useState('');
    const [subject, setSubject] = useState('');
    const [showSpell, setShowSpell] = useState(false);
    const spellResults = useRef();

    useEffect(() => {
        if (firstWandMotion && secondWandMotion && firstPhrase && secondPhrase && action && subject) {
            setShowSpell(true);
        } else {
            setShowSpell(false);
        }
    }, [firstWandMotion, secondWandMotion, firstPhrase, secondPhrase, action, subject])
    
    let wandMotions = [
        "up",
        "down",
        "left",
        "right",
        "swish",
        "flick",
        "arch",
        "square",
        "circle",
        "flourish",
        "wave",
    ];

    let phrases = [
        "hoogey-baga",
        "laga-laga",
        "SHONKEY",
        "shooga-looga",
        "skida-marinkey",
        "dinky-dink", 
        "baba",
        "lookai",
        "BEEEEEE-yooooo",
        "hasha-boogee",
        "la-lee-low-la-loo"
    ];

    let subjects = [
        "a chicken",
        "your sibling",
        "food",
        "you",
        "the couch in your living room",
        "your parent",
        "bologna",
        "a cup",
        "a spider",
        "a robot",
        "a nuclear reactor",
        "a spaceship",
        "a rhinoceros (aka a beefy unicorn)",
    ];
    
    let actions = [
        "change [subject] to a color you like",
        "give [subject] sticks for legs", 
        "give [subject] an elephant trunk",
        "make [subject] fly",
        "make [subject] drill into the ground",
        "change [subject] to liquid",
        "make [subject] squawk like a bird",
        "give [subject] a Lego head", 
        "make [subject] become as hot as the sun",
        "turn [subject] to ice", 
        "make [subject] grow a tail",
        "turn [subject] into hot coffee",
        "give [subject] indestructible armor",
    ];

    let wordsOfCaution = [
        "Use sparingly lest you destroy the entire planet.",
        "Be careful, this spell could change the world...for better or worse.",
        "Many a wizard has mistakenly used this while eating dinner.",
        "Once this is done, it takes three hours to wear off!",
        "This can only be reversed by counting to ten by twos.",
        "If performed on the moon, this spell will have double the power because, well, less gravity, which somehow makes spells last longer. :)",
        "Gandalf learned this spell from Saruman back when they were still friends. It may have had something to do with their friendship ending...",
        "Professor Trelawney foresaw that you would use this spell. She's watching you.",
        "Dumbledore would approve of using this spell whenever you feel like it.",
        "You're a lizard, Larry! Errr... a wizard, Harry? I can never remember.",
    ];

    const rollTheDice = () => {
        let randomIndex = Math.floor(Math.random() * 10);
        return randomIndex;
    }

    const handleChange = (event) => {
        switch (event.target.name) {
            case "firstWandMotion":
                setFirstWandMotion(event.target.value);
                break;
            case "secondWandMotion":
                setSecondWandMotion(event.target.value);
                break;
            case "firstPhrase":
                setFirstPhrase(event.target.value);
                break;
            case "secondPhrase":
                setSecondPhrase(event.target.value);
                break;
            case "subject":
                setSubject(event.target.value);
                break;
            case "action":
                setAction(event.target.value);
                break;
            default:
                break;
        }
        
    };

    const randomize = () => {
        let index = rollTheDice();
        setFirstWandMotion(wandMotions[index]);
        index = rollTheDice();
        setSecondWandMotion(wandMotions[index]);
        index = rollTheDice();
        setFirstPhrase(phrases[index]);
        index = rollTheDice();
        setSecondPhrase(phrases[index])
        index = rollTheDice();
        setSubject(subjects[index]);
        index = rollTheDice();
        setAction(actions[index]);
        setShowSpell(true);
        setTimeout(() => {
            spellResults.current.scrollIntoView ({ behavior: "smooth", block: "start" });
        }, 300);
    }

    return (
        <>
            <Typography variant="h4" component="h1" style={{marginBottom: "1rem"}}>
                David's Magic Spell Generator
            </Typography>
            <Typography>Want to make your own magic spells? You've come to the right place! Design your own below, or click "RANDOMIZER" for a surprise.</Typography>
            <Typography style={{marginTop: "1rem"}}>Every good spell requires two wand motions and two phrases to perform an action on a subject. Choose wisely, and always use your newfound powers for good!</Typography>
            <div style={{margin: "1rem 0"}}>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel>First wand motion</InputLabel>
                    <Select
                        labelId="firstWandMotion-label"
                        name="firstWandMotion"
                        value={firstWandMotion}
                        onChange={handleChange}
                        label="First wand motion"
                    >
                        {wandMotions.map((value, index) => <MenuItem key={`${value}-listItem`} value={value}>{value}</MenuItem>)}   
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel>Second wand motion</InputLabel>
                    <Select
                        labelId="secondWandMotion-label"
                        name="secondWandMotion"
                        value={secondWandMotion}
                        onChange={handleChange}
                        label="Second wand motion"
                    >
                        {wandMotions.map((value, index) => <MenuItem key={`${value}-listItem`} value={value}>{value}</MenuItem>)}    
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel>First phrase</InputLabel>
                    <Select
                        labelId="firstPhrase-label"
                        name="firstPhrase"
                        value={firstPhrase}
                        onChange={handleChange}
                        label="First phrase"
                    >
                        {phrases.map((value, index) => <MenuItem key={`${value}-listItem`} value={value}>{value}</MenuItem>)}  
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel>Second phrase</InputLabel>
                    <Select
                        labelId="secondPhrase-label"
                        name="secondPhrase"
                        value={secondPhrase}
                        onChange={handleChange}
                        label="Second phrase"
                    >
                        {phrases.map((value, index) => <MenuItem key={`${value}-listItem`} value={value}>{value}</MenuItem>)}  
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel>Subject</InputLabel>
                    <Select
                        labelId="subject-label"
                        name="subject"
                        value={subject}
                        onChange={handleChange}
                        label="Subject"
                    >
                        {subjects.map((value, index) => <MenuItem key={`${value}-listItem`} value={value}>{value}</MenuItem>)}  
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel>Action</InputLabel>
                    <Select
                        labelId="action-label"
                        name="action"
                        value={action}
                        onChange={handleChange}
                        label="Action"
                    >
                        {actions.map((value, index) => <MenuItem key={`${value}-listItem`} value={value}>{value}</MenuItem>)}  
                    </Select>
                </FormControl>
            </div>
            <Button variant="contained" color="primary" className={classes.button} onClick={randomize}>Randomizer</Button>
            <div ref={spellResults}>
                {showSpell &&
                    <div style={{marginTop: "1.5rem", minHeight: "350px"}}>
                        <Typography variant="h4" component="h2">Your New Spell</Typography>
                        <Typography className={classes.spellName} variant="h5" style={{margin: "1rem 0"}}>{`${firstPhrase.charAt(0).toUpperCase()}${firstPhrase.slice(1)} ${secondPhrase}!`}</Typography>
                        <Typography style={{margin: "1rem 0"}}><span style={{fontWeight: 700}}>Wand motion:</span>{` ${firstWandMotion}, then ${secondWandMotion}.`}</Typography>
                        <Typography style={{margin: "1rem 0"}}><span style={{fontWeight: 700}}>Speak boldly with confidence:</span>{` ${firstPhrase} ${secondPhrase}!`}</Typography>
                        <Typography style={{margin: "1rem 0"}}>This spell will <span className={classes.spellEffectText}>{`${action.replace("[subject]", subject)}. `}</span>{`${wordsOfCaution[Math.floor(Math.random() * 10)]}`}</Typography>
                    </div>
                }
            </div>
        </>
    )
}
