import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import CodeIcon from '@material-ui/icons/Code';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import HomeIcon from '@material-ui/icons/Home';
import FlareIcon from '@material-ui/icons/Flare';
import { Home } from './Home';
import { Adventures } from './Adventures';
import { Science } from './Science';
import { Games } from './Games';
import { SecretCodes } from './SecretCodes';
import { Funny } from './Funny';
import { MagicSpells } from './MagicSpells';
import {
    HashRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

export default function PersistentDrawerLeft() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const iconList = [
        <HomeIcon color='secondary' />,
        <FilterHdrIcon color='secondary' />,
        <VideogameAssetIcon color='secondary' />,
        <FlareIcon color='secondary' />,
        <FingerprintIcon color='secondary' />,
        <AllInclusiveIcon color='secondary' />,
        <EmojiEmotionsIcon color='secondary' />,
    ];

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                {[
                    { name: 'home', alias: 'Home' },
                    { name: 'adventures', alias: 'Adventures' },
                    { name: 'games', alias: 'Games' },
                    { name: 'magic-spells', alias: 'Magic Spells' },
                    // { name: 'science', alias: 'Science' },
                    // { name: 'secret-codes', alias: 'Secret Codes' },
                    // { name: 'funny', alias: 'Funny' },
                ].map((value, index) => (
                    <Link key={`${value.name}-link`} to={`/${value.name}`} style={{ textDecoration: 'none' }}>
                        <ListItem button key={value.name}>
                            <ListItemIcon>{iconList[index]}</ListItemIcon>
                            <ListItemText primary={value.alias} style={{color: 'black'}}/>
                        </ListItem>
                    </Link>
                ))}
            </List>
            <Divider />
        </div>
    );

    return (
        <Router basename="/">
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h5" noWrap>
                            Welcome to David's World
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    {drawer}
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Switch>
                        <Route path="/adventures">
                            <Adventures />
                        </Route>
                        <Route path="/games">
                            <Games />
                        </Route>
                        <Route path="/magic-spells">
                            <MagicSpells />
                        </Route>
                        {/* <Route path="/science">
                            <Science />
                        </Route> */}
                        {/* <Route path="/secret-codes">
                            <SecretCodes />
                        </Route> */}
                        {/* <Route path="/funny">
                            <Funny />
                        </Route> */}
                        <Route path="/home">
                            <Home />
                        </Route>
                        <Route path="/">
                            <Redirect to="/home"/>
                        </Route>
                    </Switch>
                </main>
            </div>
        </Router>
    );
}
