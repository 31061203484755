import React from 'react';
import { Typography } from '@material-ui/core';
import aloyGraveHoard from "../img/aloyGraveHoard.jpg";
import aloyDevilsThirst from '../img/aloyDevilsThirst.png';
import aloyThunderJaw from '../img/aloyThunderJaw.jpg';
import snapMawDodge from '../img/snapMawDodge.gif';

export const Games = (props) => {
    return (
        <>
            <img src={aloyGraveHoard} alt="Aloy at the Grave Hoard" style={{ marginTop: "20px", maxWidth: "100%" }}/>
            <img src={aloyDevilsThirst} alt="Aloy at Devil's Thirst" style={{ marginTop: "20px", maxWidth: "100%" }}/>
            <img src={aloyThunderJaw} alt="Aloy with a thunderjaw" style={{ marginTop: "20px", maxWidth: "100%" }}/>
            <img src={snapMawDodge} alt="Aloy with a snapmaw" style={{ marginTop: "20px", maxWidth: "100%" }}/>
        </>
    )
}
